import React from 'react';
import {
  Button, Col, Row
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

export default function SuccessStep(): React.ReactElement {
  const { t } = useTranslation();
  return (
    <Row className="justify-content-center">
      <Col className="text-center">
        <h4 className="m-4">
          {t('Thank you, our sales team will contact you as soon as possible')}
        </h4>
        <Button>Go to home</Button>
      </Col>
    </Row>
  );
}
