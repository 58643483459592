import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col, Container } from 'react-bootstrap';
import { Step, Stepper } from 'react-form-stepper';

import { StepperContext } from '@modules/qualifying_forms/stepper-context';
import { ServiceRequestContext } from '@modules/qualifying_forms/service-request-context';
import GetToKnowUs from '@modules/qualifying_forms/steps/get-to-know-us';

import { FormStepper as FormStepperModel } from '@models/form-stepper';

import ServiceStep from './steps/service/service-step';
import CompanyStep from './steps/company/company-step';
import ContactStep from './steps/contact/contact-step';
import SuccessStep from './steps/success-step';

const connectorStyleProps = {
  activeBgColor: '#cedbeb',
  activeTextColor: '#022a4e',
  completedBgColor: '#cedbeb',
  completedTextColor: '#022a4e',
  inactiveBgColor: '#e1e1e1',
  inactiveTextColor: '#756e6e',
  size: '2em',
  circleFontSize: '1rem',
  labelFontSize: '0.875rem',
  borderRadius: '50%',
  fontWeight: 500
};

const emptyServiceRequest = {
  service: '',
  description: '',
  appendDescription: '',
  details: [],
  typeOfCompany: '',
  accountId: 0,
  companyName: '',
  companyCountry: 0,
  companySector: 0,
  companyDimension: 0,
  contactId: 0,
  contactEmail: '',
  contactName: '',
  contactLastName: '',
  contactPhoneCountry: '',
  contactPhone: '',
  contactRole: '',
  contactComment: '',
  token: ''
};

type FormStepperProps = {
  stepper: FormStepperModel
};

export default function FormStepper({ stepper }: FormStepperProps): React.ReactElement {
  const { t } = useTranslation();

  // TODO Remove this any
  const changeServiceRequest = (serviceRequest: any) => {
    setServiceRequest((previousServiceRequest) => ({
      ...previousServiceRequest,
      serviceRequest
    }));
  };

  const [serviceRequest, setServiceRequest] = useState({
    serviceRequest: emptyServiceRequest,
    changeServiceRequest
  });

  const [stepIndicator, setStepIndicator] = useState(0);

  const steps = [t('Customer type'), t('Type of service'), t('Detail of your service'), t('Your expectations'), t('Company information'), t('Contact information')];

  useEffect(() => {
    const newStepIndicator = steps.indexOf(stepper.label);
    setStepIndicator(newStepIndicator !== -1 ? newStepIndicator : 0);
  }, [stepper]);

  return (
    <Container className="mt-4 mb-4 d-flex">
      <Row className="w-100">
        <Col>
          <ServiceRequestContext.Provider value={serviceRequest}>
            <StepperContext.Provider value={stepper}>
              {(stepper.step !== 'SuccessStep' || 'GetToKnowUs') && (
                <Stepper activeStep={stepIndicator} styleConfig={connectorStyleProps}>
                  {steps.map((step) => (
                    <Step key={step} label={step} />
                  ))}
                </Stepper>
              )}

              {stepper.step === 'GetToKnowUs' && (
                <GetToKnowUs />
              )}

              {stepper.step === 'ServiceStep' && (
                <ServiceStep />
              )}

              {stepper.step === 'CompanyStep' && (
                <CompanyStep />
              )}

              {stepper.step === 'ContactStep' && (
                <ContactStep />
              )}
              {stepper.step === 'SuccessStep' && (
                <SuccessStep />
              )}
            </StepperContext.Provider>
          </ServiceRequestContext.Provider>
        </Col>
      </Row>
    </Container>
  );
}
