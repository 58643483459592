import React, { FormEvent, useContext } from 'react';
import {
  Container,
  Row,
  Form,
  Button
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { ServiceRequestContext } from '@modules/qualifying_forms/service-request-context';
import { StepperContext } from '@modules/qualifying_forms/stepper-context';

import { EventTarget } from '@models/event-target';

interface StateInterface {
  quality: string;
  priceOriented: string;
  howDemandingAreYou: string;
  budget: string;
}

export default function BudgetStep() {
  const { t } = useTranslation();
  const { serviceRequest, changeServiceRequest } = useContext(
    ServiceRequestContext
  );
  const stepper = useContext(StepperContext);
  const [formAppend, setformAppend] = React.useState<StateInterface>({
    quality: '',
    priceOriented: '',
    howDemandingAreYou: '',
    budget: '',
  });

  const budgetOptions = [
    '$5,000 - $10,000 USD',
    '$10,000 - $20,000 USD',
    '$20,000 - $30,000 USD',
    '$30,000 - $50,000 USD',
    '$50,000 - $100,000 USD',
    '$100,000 - $200,000 USD',
    '$200,000 - $500,000 USD',
    '$500,000 - $1,000,000 USD',
    '$1,000,000 - $2,000,000 USD',
    `${t('More than')} $2,000,000  US`
  ];

  const handleInputChange = (event: EventTarget) => {
    setformAppend({ ...formAppend, [event.target.id]: event.target.value });
  };

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const appendDescription = `${serviceRequest.appendDescription}
    \n quality: ${formAppend?.quality}
    \n priceOriented: ${formAppend?.priceOriented}
    \n howDemandingAreYou: ${formAppend?.howDemandingAreYou}
    \n budget: ${formAppend?.budget}`;

    changeServiceRequest({
      ...serviceRequest,
      appendDescription,
    });

    stepper.changeStepper('CompanyStep', 'CompanyTypeSubstep', t('Company information'));
  };

  return (
    <Container fluid>
      <Row className="justify-content-center my-4">
        <h4 className="mb-4">
          {t('Your expectations')}
          <span className="required ml-1">*</span>
        </h4>
      </Row>
      <Row className=" justify-content-center">
        <Form noValidate className="w-75" onSubmit={handleSubmit}>
          <Row className="justify-content-center">
            <Form.Group className="w-75">
              <Form.Label>{`${t('Please select a type of')} ${t('quality')}`}</Form.Label>
              <Form.Control
                as="select"
                id="quality"
                onChange={(event) => handleInputChange(event as unknown as EventTarget)}
                required
                value={formAppend.quality}
              >
                <option value="Very High">{t('Very High')}</option>
                <option value="High">{t('High')}</option>
                <option value="Mid">{t('Mid')}</option>
              </Form.Control>
            </Form.Group>
          </Row>
          <Row className="justify-content-center">
            <Form.Group className="w-75">
              <Form.Label>{`${t('Please select a type of')} ${t('Price oriented')}`}</Form.Label>
              <Form.Control
                as="select"
                id="priceOriented"
                onChange={(event) => handleInputChange(event as unknown as EventTarget)}
                required
                value={formAppend.priceOriented}
              >
                <option value="Quality and Service over price">
                  {t('Quality and Service over price')}
                </option>
                <option value="Prices based on the standard of the industry">
                  {t('Prices based on the standard of the industry')}
                </option>
                <option value="Low budget">{t('Low budget')}</option>
              </Form.Control>
            </Form.Group>
          </Row>
          <Row className="justify-content-center">
            <Form.Group className="w-75">
              <Form.Label>{`${t('How demanding are you')}`}</Form.Label>
              <Form.Control
                as="select"
                id="howDemandingAreYou"
                onChange={(event) => handleInputChange(event as unknown as EventTarget)}
                required
                value={formAppend.howDemandingAreYou}
              >
                <option value="Very demanding">{t('Very demanding')}</option>
                <option value="Manageable">{t('Manageable')}</option>
                <option value="Normal">{t('Normal')}</option>
              </Form.Control>
            </Form.Group>
          </Row>
          <Row className="justify-content-center">
            <Form.Group className="w-75">
              <Form.Label>{`${t("What's the range of your budget")}`}</Form.Label>
              <Form.Control
                as="select"
                id="budget"
                onChange={(event) => handleInputChange(event as unknown as EventTarget)}
                required
                value={formAppend.budget}
              >
                {
                  budgetOptions.map((item: string) => (
                    <option key={item} value={item}>
                      {item}
                    </option>
                  ))
                }
              </Form.Control>
            </Form.Group>
          </Row>
          <Row className="justify-content-center mt-4">
            <Button
              onClick={() => {
                stepper.changeStepper('ServiceStep', 'ServiceDetailsSubstep', t('Detail of your service'));
              }}
              variant="outline-primary"
              className="w-25 mr-1"
            >
              {t('Previous')}
            </Button>
            <Button
              disabled={serviceRequest.description === ''}
              className="btn btn-lg btn-primary w-25"
              type="submit"
            >
              {t('Next')}
            </Button>
          </Row>
        </Form>
      </Row>
    </Container>
  );
}
