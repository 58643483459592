import React, { useContext } from 'react';

import { StepperContext } from '@modules/qualifying_forms/stepper-context';
import ServiceTypeSubstep from '@modules/qualifying_forms/steps/service/service-type-substep';
import ServiceSelectSubstep from '@modules/qualifying_forms/steps/service/service-select-substep';
import ServiceDetailsSubstep from '@modules/qualifying_forms/steps/service/service-details-substep';

import BudgetStep from './BudgetStep';
import CompanyTokenSubstep from '../company/company-token';

export default function ServiceStep(): React.ReactElement {
  const stepper = useContext(StepperContext);

  switch (stepper.substep) {
    case 'ServiceTypeSubstep':
      return <ServiceTypeSubstep />;
    case 'ServiceSelectSubstep':
      return <ServiceSelectSubstep />;
    case 'ServiceDetailsSubstep':
      return <ServiceDetailsSubstep />;
    case 'budgetDetailSubstep':
      return <BudgetStep />;
    case 'companyTokenSubstep':
      return <CompanyTokenSubstep />;
    default:
      return <></>;
  }
}
