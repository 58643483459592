import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, ListGroup } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

import { Graphql } from '@models/graphql';
import { EventTarget } from '@models/event-target';

import './multi-select.scss';

type MultiSelectProps = {
  options: Graphql[];
  onValuesChange: Function
};

export default function MultiSelect({
  options, onValuesChange
}: MultiSelectProps): React.ReactElement {
  if (options.length <= 0) {
    return <></>;
  }

  const { t } = useTranslation();
  const [searchText, setSearchText] = useState('');
  const [values, setValues] = useState<Graphql[]>([]);

  const isOptionSelected = (option: Graphql) => !!values.find((value) => value.id === option.id);
  const filterBySearchText = (option: Graphql) => {
    if (!searchText) {
      return true;
    }

    const { title } = option;

    return title?.toLocaleLowerCase().includes(searchText.toLocaleLowerCase());
  };

  const onValuesChangeListener = (option: Graphql) => {
    let newValues;
    const existsValue = isOptionSelected(option);

    if (!existsValue) {
      newValues = values.concat([option]);
    } else {
      newValues = values.filter((value) => value.id !== option.id);
    }

    setValues(newValues);
    onValuesChange(newValues);
  };

  const onSearchTextChangeLitener = (event: EventTarget) => {
    const { target } = event;
    setSearchText(target.value);
  };

  return (
    <div className="text-left w-100">
      {values.length > 0 && (
        <div className="multi-select d-flex border border-radius p-2 flex-wrap mb-4">
          {values
            .map((option) => (
              <Button
                key={option.id}
                className="text-left p-1 mr-2 mb-2 border d-flex align-items-center text-dark-indigo"
                variant="link"
                onClick={() => onValuesChangeListener(option)}
              >
                {option.title}
                <span className="ml-2">
                  <FontAwesomeIcon icon={faTimes as IconProp} />
                </span>
              </Button>
            ))}
        </div>
      )}

      <ListGroup className="multi-select border-top border-bottom">
        <ListGroup.Item className="sticky-top px-3 top-0">
          <input
            type="text"
            className="form-control"
            onChange={(event) => onSearchTextChangeLitener(event as unknown as EventTarget)}
            placeholder={t('Search')}
          />
        </ListGroup.Item>
        {options
          .filter((option: Graphql) => filterBySearchText(option))
          .filter((option: Graphql) => !isOptionSelected(option))
          .map((option: Graphql) => (
            <ListGroup.Item
              key={option.id}
              className="cursor-pointer"
              active={isOptionSelected(option)}
              onClick={() => onValuesChangeListener(option)}
            >
              {option.title}
            </ListGroup.Item>
          ))}
      </ListGroup>
    </div>
  );
}
