import React, { useState, useContext } from 'react';
import {
  Button, Col, Container, Form as BootstrapForm, Row
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import ImageProvider from '@core/providers/image/image-provider';

import { StepperContext } from '@modules/qualifying_forms/stepper-context';

import { EventTarget } from '@models/event-target';

export default function ServiceTypeSubstep(): React.ReactElement {
  const { t } = useTranslation();
  const [value, setValue] = useState<string|null>(null);

  const stepper = useContext(StepperContext);

  const onValueChange = (event: EventTarget) => {
    setValue(event.target.value);
  };

  const options = [
    {
      value: 'newService',
      label: t('New service'),
      activeIcon: 'bell.svg',
      inactiveIcon: 'bell 2.svg'
    },
    {
      value: 'previousService',
      label: t('Previous service'),
      activeIcon: 'Active Soft 2.svg',
      inactiveIcon: 'Active Soft.svg'
    },
  ];

  const optionElements = options.map((option) => (
    <Col
      key={option.value}
      className="d-flex flex-row justify-content-center align-items-center"
    >
      <ImageProvider
        fileName={
          value === option.value ? option.activeIcon : option.inactiveIcon
        }
        alt={option.label}
      />
      <BootstrapForm.Check
        key={option.value}
        id={option.value}
        type="radio"
        name="services"
        value={option.value}
        label={option.label}
        defaultChecked={value === option.value}
        onChange={(event) => (onValueChange(event as unknown as EventTarget))}
        inline
        className="ml-4 form-control-sm"
      />
    </Col>
  ));

  return (
    <Container fluid>
      <BootstrapForm className="mb-5">
        <BootstrapForm.Group>
          <Row className="mt-4 justify-content-center">
            <h4>{t('Customer type')}</h4>
          </Row>
          <Row className="justify-content-center standard-height w-75 w-lg-50 mx-auto">
            {optionElements}
          </Row>
        </BootstrapForm.Group>
      </BootstrapForm>

      <Row className="justify-content-center mt-4">
        <Button
          disabled={value === null}
          onClick={() => {
            stepper.changeStepper('ServiceStep', value === 'newService' ? 'ServiceSelectSubstep' : 'companyTokenSubstep', t('Type of service'));
          }}
          className="btn btn-lg btn-primary w-25"
        >
          {t('Next')}
        </Button>
      </Row>
    </Container>
  );
}
