import React from 'react';

import { QualityFormFields } from '@models/qualityform-fields';

export type ServiceRequestContextType = {
  serviceRequest: QualityFormFields,
  changeServiceRequest:(_serviceRequest: QualityFormFields) => void
};

export const ServiceRequestContext = React.createContext<ServiceRequestContextType>({
  serviceRequest: {
    service: '',
    description: '',
    appendDescription: '',
    details: [],
    typeOfCompany: '',
    accountId: 0,
    companyName: '',
    companyCountry: 0,
    companySector: 0,
    companyDimension: 0,
    contactId: 0,
    contactEmail: '',
    contactName: '',
    contactLastName: '',
    contactPhone: '',
    contactPhoneCountry: '',
    contactRole: '',
    contactComment: '',
    token: '',
    automation_key: ''
  },
  changeServiceRequest: (_serviceRequest) => {}
});
