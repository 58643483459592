import React, { FormEvent, useContext } from 'react';
import {
  Button, Container, Form, Row
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { ServiceRequestContext } from '@modules/qualifying_forms/service-request-context';
import { StepperContext } from '@modules/qualifying_forms/stepper-context';

import { EventTarget } from '@models/event-target';

interface StateInterface {
  typeOfCompany: string;
}

export default function CompanyTypeSubstep(): React.ReactElement {
  const { t } = useTranslation();
  const { serviceRequest, changeServiceRequest } = useContext(
    ServiceRequestContext
  );

  const stepper = useContext(StepperContext);
  const [formAppend, setFormAppend] = React.useState<StateInterface>({
    typeOfCompany: ''
  });

  const handleInputChange = (event: EventTarget) => {
    setFormAppend({ ...formAppend, [event.target.id]: event.target.value });
  };

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    changeServiceRequest({
      ...serviceRequest,
      typeOfCompany: formAppend.typeOfCompany
    });

    stepper.changeStepper(
      'CompanyStep',
      'CompanyInformationSubstep',
      t('Company information')
    );
  };

  return (
    <Container fluid>
      <Row className="justify-content-center my-4">
        <h4 className="mb-4">
          {t('Type of company')}
          <span className="required ml-1">*</span>
        </h4>
      </Row>
      <Row className=" justify-content-center">
        <Form noValidate className="w-75" onSubmit={handleSubmit}>
          <Row className="justify-content-center">
            <Form.Group className="w-75">
              <Form.Label>
                {`${t('Please select a type of')} ${t('company')}`}
              </Form.Label>
              <Form.Control
                as="select"
                id="typeOfCompany"
                onChange={(event) => handleInputChange(event as unknown as EventTarget)}
                required
                value={formAppend.typeOfCompany}
              >
                <option value="Startup with funding">
                  {t('Startup with funding')}
                </option>
                <option value="Business">{t('Business')}</option>
                <option value="Personal project">
                  {t('Personal project')}
                </option>
              </Form.Control>
            </Form.Group>
          </Row>
          <Row className="justify-content-center mt-4">
            <Button
              onClick={() => {
                stepper.changeStepper(
                  'ServiceStep',
                  'budgetDetailSubstep',
                  t('Your expectations')
                );
              }}
              variant="outline-primary"
              className="w-25 mr-1"
            >
              {t('Previous')}
            </Button>
            <Button
              disabled={formAppend.typeOfCompany === ''}
              className="btn btn-lg btn-primary w-25"
              type="submit"
            >
              {t('Next')}
            </Button>
          </Row>
        </Form>
      </Row>
    </Container>
  );
}
