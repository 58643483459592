import React, { useContext } from 'react';

import { StepperContext } from '@modules/qualifying_forms/stepper-context';

import CompanyTypeSubstep from '@modules/qualifying_forms/steps/company/company-type-substep';
import CompanyInformationSubstep from '@modules/qualifying_forms/steps/company/company-information-substep';
import CompanyTokenInformation from '@modules/qualifying_forms/steps/company/company-token-information';

export default function CompanyStep(): React.ReactElement {
  const stepper = useContext(StepperContext);

  if (stepper.substep === 'CompanyTypeSubstep') {
    return <CompanyTypeSubstep />;
  }

  if (stepper.substep === 'CompanyInformationSubstep') {
    return <CompanyInformationSubstep />;
  }

  if (stepper.substep === 'CompanyTokenInformationSubstep') {
    return <CompanyTokenInformation />;
  }

  return <></>;
}
