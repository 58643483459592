import React, {
  FormEvent,
  useContext,
  useEffect,
  useState
} from 'react';
import {
  Button,
  Form as BootstrapForm,
  Row,
  Col
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import {
  fetchContactInformation,
  sendOpportunity,
  fetchAccountInformation,
  updateContact,
} from '@core/services/rootnet-service';

import { ServiceRequestContext } from '@modules/qualifying_forms/service-request-context';
import { StepperContext } from '@modules/qualifying_forms/stepper-context';

import { ContactData } from '@models/contact-data';
import { AccountData } from '@models/account-data';
import { EventTarget } from '@models/event-target';

const emptyContactList = {
  roles: [],
  countries: []
};

export default function ContactStep(): React.ReactElement {
  const { t } = useTranslation();
  const stepper = useContext(StepperContext);
  const { serviceRequest, changeServiceRequest } = useContext(
    ServiceRequestContext
  );
  const [failedSend, setFailedSent] = React.useState<boolean>(false);
  const [contactLists, setContactLists] = useState<ContactData>(
    emptyContactList
  );
  const [isInvalid, setIsInvalid] = useState({
    email: false,
    name: false,
    lastName: false,
    phone: false,
    role: false,
  });

  const [submitError] = useState(false);
  const [accountInfo, setAccountInfo] = useState<AccountData>({
    countries: [],
    sector: [],
    dimension: [],
  });

  const handleInputChange = (event: EventTarget) => {
    if (event.target.name === 'contactEmail' && isInvalid.email) {
      isInvalid.email = false;
    }

    if (event.target.name === 'contactName' && isInvalid.name) {
      isInvalid.name = false;
    }

    if (event.target.name === 'contactLastname' && isInvalid.lastName) {
      isInvalid.lastName = false;
    }

    if (event.target.name === 'contactPhone' && isInvalid.phone) {
      isInvalid.phone = false;
    }

    if (event.target.name === 'contactRole' && isInvalid.role) {
      isInvalid.role = false;
    }

    changeServiceRequest({
      ...serviceRequest,
      [event.target.name]: event.target.value
    });
  };

  useEffect(() => {
    const fetchAccountList = async () => {
      const response = await fetchAccountInformation();
      setAccountInfo((response as unknown) as AccountData);
    };

    fetchAccountList();
  }, []);

  useEffect(() => {
    const contactList = async () => {
      const contactL = await fetchContactInformation();
      setContactLists(
        ((contactL as unknown) as ContactData) || emptyContactList
      );
    };

    contactList();
  }, []);

  useEffect(() => {
    serviceRequest.contactPhoneCountry = contactLists?.countries?.length
      ? contactLists.countries[0].id
      : '';
  }, [contactLists]);

  const createOpportunity = async () => {
    const description = `${serviceRequest.description}\n Type of Company: ${serviceRequest.typeOfCompany} \n ${serviceRequest.appendDescription}`;
    const opportunityBody: any = {
      service: serviceRequest.service,
      description
    };

    if (serviceRequest.accountId !== null) {
      opportunityBody.account = serviceRequest.accountId;
    } else {
      opportunityBody.account = {
        company: serviceRequest.companyName,
        country: serviceRequest.companyCountry,
        sector: serviceRequest.companySector,
        dimension: serviceRequest.companyDimension,
      };
    }

    if (serviceRequest.contactId !== null && serviceRequest.contactId !== undefined && serviceRequest.contactId > 0) {
      opportunityBody.contact = serviceRequest.contactId;
    } else {
      opportunityBody.contact = {
        name: serviceRequest.contactName,
        last_name: serviceRequest.contactLastName,
        email: serviceRequest.contactEmail,
        phone: {
          id: serviceRequest.contactPhoneCountry,
          value: serviceRequest.contactPhone
        },
        role: serviceRequest.contactRole,
        comment: serviceRequest.contactComment
      };
    }
    opportunityBody.automation_key = '';

    const SP = await sendOpportunity(opportunityBody);
    if (SP) {
      stepper.changeStepper('SuccessStep');
    } else {
      setFailedSent(true);
      isInvalid.email = true;
      isInvalid.name = true;
      isInvalid.lastName = true;
      isInvalid.phone = true;
      isInvalid.role = true;
      setIsInvalid({ ...isInvalid });
    }
  };

  const updateContactData = async () => {
    const contactBody: any = {
      email: serviceRequest.contactEmail,
      name: serviceRequest.contactName,
      last_name: serviceRequest.contactLastName,
      phone: { country_id: serviceRequest.contactPhoneCountry, value: serviceRequest.contactPhone },
      role: serviceRequest.contactRole
    };

    const SP = await updateContact(`${serviceRequest.contactId}`, contactBody);
    if (SP) {
      stepper.changeStepper('SuccessStep');
    } else {
      setFailedSent(true);
      isInvalid.email = true;
      isInvalid.name = true;
      isInvalid.lastName = true;
      isInvalid.phone = true;
      isInvalid.role = true;
      setIsInvalid({ ...isInvalid });
    }
  };
  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault();
    Object.keys(isInvalid).forEach((key) => (isInvalid[key] = false));
    if (serviceRequest.contactId != null && serviceRequest.contactId !== 0) {
      updateContactData();
    } else {
      isInvalid.email = serviceRequest.contactEmail === '';
      isInvalid.name = serviceRequest.contactName === '';
      isInvalid.lastName = serviceRequest.contactLastName === '';
      isInvalid.phone = serviceRequest.contactPhone === '';
      isInvalid.role = serviceRequest.contactRole === '';

      const allValid = Object.values(isInvalid).indexOf(true) === -1;

      if (allValid) {
        createOpportunity();
      } else {
        setIsInvalid({ ...isInvalid });
      }
    }
  };

  return (
    <Row className="justify-content-center">
      <h4 className="my-4 text-center">{t('Create or verify your contact information')}</h4>
      <BootstrapForm className="w-75" noValidate onSubmit={handleSubmit}>
        <BootstrapForm.Group>
          <BootstrapForm.Label className="mb-1 mt-2">
            {t('E-mail')}
            <span className="required ml-1">*</span>
          </BootstrapForm.Label>
          <BootstrapForm.Control
            type="email"
            name="contactEmail"
            onChange={(event) => {
              handleInputChange(event as unknown as EventTarget);
            }}
            value={serviceRequest.contactEmail}
          />
        </BootstrapForm.Group>

        <div>
          <BootstrapForm.Group>
            <BootstrapForm.Label className="mb-1 mt-2">
              {t('Name')}
              <span className="required ml-1">*</span>
            </BootstrapForm.Label>
            <BootstrapForm.Control
              isInvalid={isInvalid.name}
              type="text"
              name="contactName"
              onChange={(event) => handleInputChange(event as unknown as EventTarget)}
              value={serviceRequest?.contactName}
            />
          </BootstrapForm.Group>

          <BootstrapForm.Group>
            <BootstrapForm.Label className="mb-1 mt-2">
              {t('Lastname')}
              <span className="required ml-1">*</span>
            </BootstrapForm.Label>
            <BootstrapForm.Control
              isInvalid={isInvalid.lastName}
              type="text"
              name="contactLastName"
              onChange={(event) => handleInputChange(event as unknown as EventTarget)}
              value={serviceRequest?.contactLastName}
            />
          </BootstrapForm.Group>

          <BootstrapForm.Group>
            <BootstrapForm.Label className="mb-1 mt-2">
              {t('Phone')}
              <span className="required ml-1">*</span>
            </BootstrapForm.Label>
            <Row>
              <Col lg="3">
                <BootstrapForm.Control
                  as="select"
                  name="contactPhoneCountry"
                  onChange={(event) => handleInputChange(event as unknown as EventTarget)}
                  value={serviceRequest.contactPhoneCountry}
                >
                  <option hidden value="">
                    {t('Select')}
                  </option>

                  {accountInfo?.countries
                    && accountInfo?.countries.map((role) => (
                      <option key={`${role.id}`} value={role.id}>
                        {role.name}
                      </option>
                    ))}
                </BootstrapForm.Control>
              </Col>
              <Col lg="9">
                <BootstrapForm.Control
                  isInvalid={isInvalid.phone}
                  type="tel"
                  name="contactPhone"
                  onChange={(event) => handleInputChange(event as unknown as EventTarget)}
                  value={serviceRequest.contactPhone}
                />
              </Col>
            </Row>
          </BootstrapForm.Group>

          <BootstrapForm.Group>
            <BootstrapForm.Label className="mb-1 mt-2">
              {t('Role')}
              <span className="required ml-1">*</span>
            </BootstrapForm.Label>
            <BootstrapForm.Control
              as="select"
              name="contactRole"
              onChange={(event) => handleInputChange(event as unknown as EventTarget)}
            >
              <option hidden value="">
                {t('Select')}
              </option>
              {contactLists.roles.map((role) => (
                <option key={`${role.id}`} value={role.id}>
                  {role.name}
                </option>
              ))}
            </BootstrapForm.Control>
          </BootstrapForm.Group>

          <BootstrapForm.Group>
            <BootstrapForm.Label className="mb-1 mt-2">
              {t('Comment')}
            </BootstrapForm.Label>
            <BootstrapForm.Control
              type="text"
              as="textarea"
              rows={3}
              name="contactComment"
              onChange={(event) => handleInputChange(event as unknown as EventTarget)}
            />
          </BootstrapForm.Group>
          {submitError === true && (
            <p>{t('Error, please contact support.')}</p>
          )}
        </div>
        {failedSend && (
          <Row className="justify-content-center mt-4">
            <h4 className="m-4">
              {t('Error has ocurred. Please contact support@rootstack.com')}
            </h4>
          </Row>
        )}

        <Row className="justify-content-center mt-4">
          <Button
            onClick={() => {
              stepper.changeStepper('CompanyStep', 'CompanyInformationSubstep', t('Company information'));
            }}
            variant="outline-primary"
            className="w-25 mr-1"
          >
            {t('Previous')}
          </Button>
          <Button
            type="submit"
            className="btn btn-lg btn-primary w-25"
          >
            {t('Finish')}
          </Button>
        </Row>
      </BootstrapForm>
    </Row>
  );
}
