import React, { useState } from 'react';
import {
  Col, Row, Tab, Tabs
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import ImageProvider from '@core/providers/image/image-provider';
import translateContent from '@core/helpers/translation';

import CardWithoutBorder from '@shared/card/card-without-border';

import allNodeServiceNeedsQuery from '@modules/qualifying_forms/steps/service/services-query';

import { Graphql } from '@models/graphql';

export default function GetToKnowUs(): React.ReactElement {
  const { i18n } = useTranslation();
  const [key, setKey] = useState<string|null>('');

  const getServices = () => {
    const data = allNodeServiceNeedsQuery();
    const services = translateContent(data?.services?.edges ?? [], i18n.language);

    let servicesList = [];

    if (data.itemsLimit) {
      const itemsLimit = (data.itemsLimit > services.length)
        ? services.length : data.itemsLimit;

      servicesList = new Array(itemsLimit)
        .fill(undefined)
        .map((_: undefined, index: number) => (services[index] ? services[index].node : null))
        .filter((client) => !!client);
    } else {
      servicesList = services
        .map((client: Graphql) => ({ ...(client.node as unknown as object), flipped: false }));
    }

    return servicesList;
  };

  const services = getServices();

  const scenarios = [
    { id: '0', title: 'Scenario A', image: '' },
    { id: '1', title: 'Scenario B', image: '' },
    { id: '2', title: 'Scenario C', image: '' },
    { id: '3', title: 'Scenario D', image: '' },
    { id: '4', title: 'Scenario E', image: '' },
  ];

  return (
    <Row className="justify-content-center">
      <Col className="text-center">
        <h4 className="m-4">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit.
        </h4>
        <Row>
          {(services as Graphql[]).map((service) => (
            <Col key={`${service.id}`} className="mb-4" sm={12} md={6} lg={4}>
              <CardWithoutBorder node={service as Graphql} />
            </Col>
          ))}
        </Row>
        <h4 className="m-4">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit.
        </h4>
        <Tabs
          fill
          id="scenarios"
          activeKey={key || ''}
          onSelect={(event) => setKey(event)}
          className="mb-3"
        >
          {scenarios.map((scenario) => (
            <Tab key={scenario.title} eventKey={`${scenario.title}`} title={`${scenario.title}`}>
              <div>{scenario.title}</div>
              <ImageProvider fileName={scenario.image} alt={`${scenario.title}`} />
            </Tab>
          ))}
        </Tabs>
      </Col>
    </Row>
  );
}
