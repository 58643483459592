import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link as GatsbyLink } from 'gatsby';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDoubleRight } from '@fortawesome/free-solid-svg-icons';

import DomPurify from '@core/helpers/dom-purify';
import ImageProvider from '@core/providers/image/image-provider';

import { Graphql } from '@models/graphql';

import './card.scss';

type CardWithoutBorderProps = {
  node: Graphql;
};

export default function CardWithoutBorder({ node }: CardWithoutBorderProps): React.ReactElement {
  const { t } = useTranslation();
  const { image } = node.relationships || {};

  return (
    <div className="component card bg-transparent card--borderless">
      { node?.relationships?.image?.name && (
        <GatsbyLink to={node?.link?.uri || ''}>
          <ImageProvider
            className="card-image"
            fileName={image?.name || ''}
            alt={image?.attributes?.alt || node?.image?.alt}
            title={image?.attributes?.title || node?.image?.title}
          />
        </GatsbyLink>
      )}
      <GatsbyLink to={node?.link?.uri || ''} className="card-link">
        <h3 className="card-title text-xl text-center pt-4 text-tangerine">
          {node.title}
        </h3>
      </GatsbyLink>
      <div className="card-content text-center flex-fill">
        {node?.wysiwyg && (
          <>
            {node.wysiwyg.summary && (
              <div className="card-description wysiwyg">
                <DomPurify text={node.wysiwyg.summary} />
              </div>
            )}
            {node.wysiwyg.processed && (
              <div className="card-description wysiwyg">
                <DomPurify text={node.wysiwyg.processed} />
              </div>
            )}
          </>
        )}

        {node && node.link && (
          <GatsbyLink to={node.link.uri || ''} className="card-link text-nice-blue text-bold text-center" title={node.link.title}>
            <span className="custom-text-secondary">
              {t('Learn more')}
              <FontAwesomeIcon className="ml-1" icon={faAngleDoubleRight as IconProp} />
            </span>
          </GatsbyLink>
        )}
      </div>
    </div>
  );
}
