import React, { useContext, useEffect, useState } from 'react';
import {
  Button, Col, Form as BootstrapForm, Row
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import translateContent from '@core/helpers/translation';

import allNodeServiceNeedsQuery from '@modules/qualifying_forms/steps/service/services-query';
import { ServiceRequestContext } from '@modules/qualifying_forms/service-request-context';
import { StepperContext } from '@modules/qualifying_forms/stepper-context';
import '@modules/qualifying_forms/styles.scss';

import { Graphql } from '@models/graphql';

export default function ServiceSelectSubstep(): React.ReactElement {
  const { t, i18n } = useTranslation();

  const [selectedService, setSelectedService] = useState<Graphql|null>(null);
  const stepper = useContext(StepperContext);
  const { serviceRequest, changeServiceRequest } = useContext(ServiceRequestContext);

  useEffect(() => {
    if (selectedService === null) {
      return;
    }
    changeServiceRequest({
      ...serviceRequest,
      service: selectedService.title || ''
    });
  }, [selectedService]);

  const getServices = () => {
    const data = allNodeServiceNeedsQuery();
    const services = translateContent(data?.services?.edges ?? [], i18n.language);

    let servicesList = [];

    if (data.itemsLimit) {
      const itemsLimit = (data.itemsLimit > services.length)
        ? services.length : data.itemsLimit;

      servicesList = new Array(itemsLimit)
        .fill(undefined)
        .map((_: undefined, index: number) => (services[index] ? services[index].node : null))
        .filter((client) => !!client);
    } else {
      servicesList = services
        .map((client: Graphql) => ({ ...(client.node as unknown as object), flipped: false }));
    }

    return servicesList as Graphql[];
  };

  const services = getServices();

  return (
    <BootstrapForm>
      <BootstrapForm.Group>
        <Row className="services-container justify-content-center">
          <Row className="my-4">
            <h4>{t('Do you know which service do you need?')}</h4>
          </Row>

          <Row className="service-card-section p-1 mx-auto">
            {services.map((service) => (
              <Col key={`${service?.id || ''}`} className="mb-4" sm={3} md={3} lg={3}>
                <BootstrapForm.Check
                  type="radio"
                  name="service"
                  label={service?.title}
                  value=""
                  onChange={() => { setSelectedService(service); }}
                  checked={selectedService ? selectedService?.title === service.title : false}
                  className="mt-4 mx-auto"
                  inline
                />
              </Col>
            ))}
            <Col className="mb-4" sm={3} md={3} lg={3}>
              <BootstrapForm.Check
                type="radio"
                name="service"
                label={t("I don't know which service to select")}
                value=""
                onChange={() => { setSelectedService(null); }}
                checked={selectedService === null}
                className="mt-4 mx-auto"
                inline
              />
            </Col>
          </Row>
        </Row>

        <Row className="justify-content-center mt-4">
          <Button
            onClick={() => {
              stepper.changeStepper('ServiceStep', 'ServiceTypeSubstep', t('Customer type'));
            }}
            variant="outline-primary"
            className="w-25 mr-1"
          >
            {t('Previous')}
          </Button>
          <Button
            onClick={() => {
              stepper.changeStepper('ServiceStep', 'ServiceDetailsSubstep', t('Detail of your service'));
            }}
            className="btn btn-lg btn-primary w-25"
          >
            {t('Next')}
          </Button>
        </Row>
      </BootstrapForm.Group>
    </BootstrapForm>
  );
}
