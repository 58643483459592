import React, { useState, useContext, FormEvent } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Col,
  Container,
  Form,
  Row
} from 'react-bootstrap';

import translateContent from '@core/helpers/translation';

import allNodeDetailsOfNeedQuery from '@modules/qualifying_forms/steps/service/details-of-needs-query';
import { ServiceRequestContext } from '@modules/qualifying_forms/service-request-context';
import { StepperContext } from '@modules/qualifying_forms/stepper-context';

import MultiSelect from '@shared/multi-select/multi-select';

import { Graphql } from '@models/graphql';
import { EventTarget } from '@models/event-target';
import { RootnetItem } from '@shared/models/rootnet';

export default function ServiceDetailsSubstep(): React.ReactElement {
  const { t, i18n } = useTranslation();
  const { serviceRequest, changeServiceRequest } = useContext(ServiceRequestContext);
  const stepper = useContext(StepperContext);
  const listsData = allNodeDetailsOfNeedQuery();

  const getListByDropdownType = (type: string) => (
    translateContent(
      listsData ? (listsData[type]?.edges ?? []) : [],
      i18n.language
    ).map((content: Graphql) => (content.node)) as Graphql[]
  );

  const [isInvalid, setIsInvalid] = useState(false);
  const [dropdowns, setDropdowns] = useState([
    {
      type: 'solutions',
      label: t('Solutions'),
      selectedItems: serviceRequest.details[0] || []
    },
    {
      type: 'technologies',
      label: t('Technologies'),
      selectedItems: serviceRequest.details[1] || []
    },
    {
      type: 'expertiseAreas',
      label: t('Area of expertise'),
      selectedItems: serviceRequest.details[2] || []
    },
  ]);

  const onSelectionDropdownItemChange = (dropdownIndex: number, values: object[]) => {
    const tmpDropdowns = dropdowns;
    tmpDropdowns[dropdownIndex].selectedItems = values;
    setDropdowns(tmpDropdowns);
  };

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsInvalid(false);

    if (serviceRequest.description.length <= 10) {
      event.stopPropagation();
      setIsInvalid(true);
    } else {
      const mapDescription = dropdowns.map((dropdown) => `${dropdown.label}: ${dropdown.selectedItems?.map((item) => (item as RootnetItem).title).join(', ')}`);
      const details = dropdowns.map((dropdown) => dropdown.selectedItems);
      const appendDescription = mapDescription.join('\n');
      changeServiceRequest({
        ...serviceRequest,
        appendDescription,
        details
      });

      stepper.changeStepper('ServiceStep', 'budgetDetailSubstep', t('Your expectations'));
    }
  };

  const handleInputChange = (event: EventTarget) => {
    const { name, value } = event.target || {};

    if (event.target.name === 'description' && isInvalid === true) {
      setIsInvalid(false);
    }

    changeServiceRequest({
      ...serviceRequest,
      [name]: value
    });
  };

  return (
    <Container fluid>
      <Row className="my-2 justify-content-center">
        <Form className="w-75" noValidate onSubmit={handleSubmit}>
          <Row className="justify-content-center">
            <h4 className="mb-4 mt-4">{t('Tell us a little more')}</h4>
          </Row>
          <Row className="justify-content-center">
            {dropdowns.map((dropdown, index) => (
              <Col
                key={dropdown.type}
                className="text-align-center mt-2"
                sm={12}
                md={6}
                lg={4}
              >
                <Col>
                  <Row className="justify-content-between">
                    <p className="font-weight-bold">{dropdown.label}</p>
                    <MultiSelect
                      options={getListByDropdownType(dropdown.type)}
                      onValuesChange={(values: object[]) =>
                        onSelectionDropdownItemChange(index, values)
                      }
                    />
                  </Row>
                </Col>
              </Col>
            ))}
          </Row>
          <Row className="justify-content-center">
            <h4 className="my-4">{t('Details of your need')}</h4>
          </Row>
          <Form.Group controlId="validateDescription">
            <Form.Label>
              {t('Description')}
              <span className="required ml-1">*</span>
            </Form.Label>
            <Form.Control
              isInvalid={isInvalid}
              required
              className="big-form-control-text"
              type="text"
              as="textarea"
              rows={3}
              onChange={(event) =>
                handleInputChange(event as unknown as EventTarget)
              }
              name="description"
              value={serviceRequest.description}
              placeholder={t(
                'E.g I need the design and implementation of an e-commerce website using Drupal'
              )}
            />
            <Form.Control.Feedback type="invalid">
              {t('Please write at least 10 characters.')}
            </Form.Control.Feedback>
          </Form.Group>
          <Row className="justify-content-center mt-4">
            <Button
              onClick={() => {
                stepper.changeStepper(
                  'ServiceStep',
                  'ServiceSelectSubstep',
                  t('Type of service')
                );
              }}
              variant="outline-primary"
              className="w-25 mr-1"
            >
              {t('Previous')}
            </Button>
            <Button
              disabled={serviceRequest.description === ''}
              className="btn btn-lg btn-primary w-25"
              type="submit"
            >
              {t('Next')}
            </Button>
          </Row>
        </Form>
      </Row>
    </Container>
  );
}
