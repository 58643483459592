import React, { useContext } from 'react';
import {
  Button, Col, Form as BootstrapForm, Row
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { ServiceRequestContext } from '@modules/qualifying_forms/service-request-context';
import { StepperContext } from '@modules/qualifying_forms/stepper-context';

import { EventTarget } from '@models/event-target';

export default function CompanyTokenSubstep(): React.ReactElement {
  const { t } = useTranslation();

  const stepper = useContext(StepperContext);
  const { serviceRequest, changeServiceRequest } = useContext(ServiceRequestContext);

  const handleInputChange = (event: EventTarget) => {
    changeServiceRequest({
      ...serviceRequest,
      [event.target.id]: event.target.value
    });
  };

  const handleSubmit = async () => {
    await clientDetails();
  };

  const clientDetails = async () => {
    stepper.changeStepper('CompanyStep', 'CompanyTokenInformationSubstep', t('Company information'));
  };

  return (
    <Row className="justify-content-center">
      <Col>
        <Row className="justify-content-center">
          <h4 className="mb-4">
            {t('Company Token')}
            <span className="required ml-1">*</span>
          </h4>
        </Row>
        <Row className="justify-content-center">
          <BootstrapForm className="w-75" noValidate onSubmit={handleSubmit}>
            <BootstrapForm.Group>
              <BootstrapForm.Label>{t('Please enter the token that you have received previously on your email')}</BootstrapForm.Label>
              <BootstrapForm.Control
                required
                type="text"
                onChange={(event) => {
                  handleInputChange(event as unknown as EventTarget);
                }}
                name="token"
                id="token"
                value={serviceRequest.token}
                placeholder={t('Please enter the token that you have received previously on your email')}
              />
            </BootstrapForm.Group>
          </BootstrapForm>
        </Row>
        <Row className="justify-content-center mt-4">
          <Button
            onClick={() => {
              stepper.changeStepper('ServiceStep', 'ServiceTypeSubstep', t('Customer type'));
            }}
            variant="outline-primary"
            className="w-25 mr-1"
          >
            {t('Previous')}
          </Button>
          <Button
            type="submit"
            disabled={serviceRequest.token === ''}
            onClick={() => {
              handleSubmit();
            }}
            className="btn btn-lg btn-primary w-25"
          >
            {t('Next')}
          </Button>
        </Row>
      </Col>
    </Row>
  );
}
