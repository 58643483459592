import React, { FormEvent, useContext, useState } from 'react';
import { Button, Form as BootstrapForm, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import {
  fetchAccountData,
  fetchBussinesOpportunities,
  fetchListToApplyField,
} from '@core/services/rootnet-service';
import { isBrowser } from '@core/helpers/ssr';
import decode from '@core/services/jwt-service';

import { BusinessOportunity } from '@shared/models/bussiness-oportunity';

import { StepperContext } from '@modules/qualifying_forms/stepper-context';
import { ServiceRequestContext } from '@modules/qualifying_forms/service-request-context';

const {
  fields,
  listCountryOrigin,
  listInboudType,
} = require('@modules/qualifying_forms/config');

export default function CompanyTokenInformation(): React.ReactElement {
  const { t } = useTranslation();

  const clientDetails = async (entityId: string) => {
    const response = await fetchBussinesOpportunities(entityId);
    const businessOpportunity = response?.business_opportunity;

    const listCountry = await fetchListToApplyField(listCountryOrigin);
    const listInboud = await fetchListToApplyField(listInboudType);
    const fieldCountry = businessOpportunity?.metadatas[fields.countryOrigen];
    const fieldInboud = businessOpportunity?.metadatas[fields.inboudType];

    if (fieldCountry) {
      businessOpportunity.countryOrigin = listCountry?.items[0]?.elements?.find(
        (x) => `${x.id}` === fieldCountry
      )?.name || '';
    }

    if (fieldInboud) {
      businessOpportunity.inboudType = listInboud?.items[0]?.elements?.find(
        (x) => `${x.id}` === fieldInboud
      )?.name || '';
    }

    setBusinessOpportunity(businessOpportunity);
    await setContactFields(businessOpportunity.contact.Id || '');
  };

  async function setContactFields(id: string) {
    const { contact } = await fetchAccountData(id);
    serviceRequest.contactId = contact.subContact;
    serviceRequest.contactEmail = contact.email || '';
    serviceRequest.contactName = contact.name || '';
    serviceRequest.contactLastName = contact.last_name || '';
    serviceRequest.contactPhone = contact.phone?.value || '';
    serviceRequest.contactPhoneCountry = `${contact.phone?.country_id || ''}`;
  }

  const stepper = useContext(StepperContext);
  const { serviceRequest } = useContext(ServiceRequestContext);

  const [businessOpportunity, setBusinessOpportunity] = useState<
    BusinessOportunity
  >();

  if (isBrowser && window.location.search && !businessOpportunity) {
    const urlParams = new URLSearchParams(window.location.search);
    const decoded = decode(urlParams.get('token') || '');
    clientDetails(decoded.entityId);
  }

  if (serviceRequest.token && !businessOpportunity) {
    const decoded = decode(serviceRequest.token);
    clientDetails(decoded.entityId);
  }

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    stepper.changeStepper('ContactStep', undefined, t('Contact information'));
  };

  return (
    <Row className="justify-content-center">
      <h4 className="mb-4">{t('We want to know more about your needs')}</h4>
      <h5 className="mb-4">
        {t(
          'Here is the information we have recieved from you, please help us with this other vaulable information'
        )}
      </h5>
      <BootstrapForm className="w-75" noValidate onSubmit={handleSubmit}>
        <BootstrapForm.Group>
          <BootstrapForm.Label className="mb-1 mt-2">
            {t('Title')}
          </BootstrapForm.Label>
          <BootstrapForm.Control
            required
            type="text"
            name="title"
            value={businessOpportunity?.title}
            readOnly
          />
        </BootstrapForm.Group>
        <BootstrapForm.Group>
          <BootstrapForm.Label className="mb-1 mt-2">
            {t('Country Origin')}
          </BootstrapForm.Label>
          <BootstrapForm.Control
            required
            type="text"
            name="title"
            readOnly
            value={businessOpportunity?.countryOrigin}
          />
        </BootstrapForm.Group>
        <BootstrapForm.Group>
          <BootstrapForm.Label className="mb-1 mt-2">
            {t('Creation Date')}
          </BootstrapForm.Label>
          <BootstrapForm.Control
            required
            type="text"
            name="creationDate"
            value={businessOpportunity?.created_at}
            readOnly
          />
        </BootstrapForm.Group>
        <BootstrapForm.Group>
          <BootstrapForm.Label className="mb-1 mt-2">
            {t('Inboud Type')}
          </BootstrapForm.Label>
          <BootstrapForm.Control
            required
            type="text"
            name="inboudType"
            value={businessOpportunity?.inboudType}
            readOnly
          />
        </BootstrapForm.Group>
        <BootstrapForm.Group>
          <BootstrapForm.Label className="mb-1 mt-2">
            {t('Opportunity Number')}
          </BootstrapForm.Label>
          <BootstrapForm.Control
            required
            type="text"
            name="countryOrigin"
            readOnly
            value={businessOpportunity?.key}
          />
        </BootstrapForm.Group>
        <Row className="justify-content-center mt-4">
          <Button type="submit" className="btn btn-lg btn-primary w-25">
            {t('Next')}
          </Button>
        </Row>
      </BootstrapForm>
    </Row>
  );
}
