import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import { isBrowser } from '@core/helpers/ssr';
import decode from '@core/services/jwt-service';
import Footer from '@core/layout/footer/footer';

import Breadcrumb from '@shared/breadcrumbs/breadcrumbs';

import FormStepper from '@modules/qualifying_forms/form-stepper';

import { FormStepper as FormStepperModel } from '@models/form-stepper';

const { clientQualyfingForm: crumbs } = require('@config/breadcrumbs');

export default function QualifyingFormPage() {
  const { t } = useTranslation();

  let days = -1;

  if (isBrowser) {
    const urlParams = new URLSearchParams(window.location.search);
    const decoded = decode(urlParams.get('token') || '');

    const now = moment(decoded?.expiresAt);
    const end = moment(new Date());
    const duration = moment.duration(now.diff(end));

    days = duration.asDays();
  }

  const [expireDays] = useState(Math.floor(days) + 1);
  const [isExpired] = useState(expireDays <= 0);

  const changeStepper = (step: string, substep: string, label: string|undefined) => {
    setStepper((previousStepper) => ({
      ...previousStepper,
      step,
      substep,
      label: label || ''
    }));
  };

  const [stepper, setStepper] = useState({
    step: days > 0 ? 'CompanyStep' : 'ServiceStep',
    substep: days > 0 ? 'CompanyTokenInformationSubstep' : 'ServiceTypeSubstep',
    label: days > 0 ? t('Company information') : t('Customer type'),
    changeStepper,
  } as FormStepperModel);

  return (
    <>
      <Breadcrumb crumbs={crumbs.concat({ label: t('Apply form'), link: '#' })} />
      {stepper?.step && <FormStepper stepper={stepper} />}
      {!isExpired && (
        <span className="navbar-text text-light">
          {`${t('Form expires in ')} ${expireDays} ${t('days')}`}
        </span>
      )}
      <Footer />
    </>
  );
}
