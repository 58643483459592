import React from 'react';

export type StepperContextType = {
  step: string,
  substep?: string,
  label?: string,
  changeStepper:(_step: string, _substep?: string|null, _label?: string|null) => void
};

export const StepperContext = React.createContext<StepperContextType>({
  step: 'ServiceStep',
  substep: 'ServiceTypeSubstep',
  label: '',
  changeStepper: (_step, _substep, _label) => {}
});
