// @ts-nocheck

import React, {
  FormEvent,
  useContext, useEffect, useState
} from 'react';
import { Button, Form as BootstrapForm, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { fetchAccountInformation, getAccountId } from '@core/services/rootnet-service';

import { StepperContext } from '@modules/qualifying_forms/stepper-context';
import { ServiceRequestContext } from '@modules/qualifying_forms/service-request-context';

import { AccountData } from '@models/account-data';

export default function CompanyInformationSubstep(): React.ReactElement {
  const { t } = useTranslation();

  const stepper = useContext(StepperContext);
  const { serviceRequest, changeServiceRequest } = useContext(ServiceRequestContext);

  const [accountInfo, setAccountInfo] = useState<AccountData>({
    countries: [],
    sector: [],
    dimension: []
  });
  const [isInvalid, setIsInvalid] = useState({
    name: false,
    country: false,
    sector: false,
    dimension: false
  });
  const [accountExists, setAccountExists] = useState(null);
  const [timeout, setDebounceTimeout] = useState(null);

  const debounce = (callback, wait) => {
    clearTimeout(timeout);
    setDebounceTimeout(setTimeout(callback, wait));
  };

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    isInvalid.name = false;
    isInvalid.country = false;
    isInvalid.sector = false;
    isInvalid.dimension = false;

    if (accountExists) {
      stepper.changeStepper('ContactStep', null, t('Contact information'));
    } else {
      if (serviceRequest.companyName.length < 3) {
        isInvalid.name = true;
      }

      if (serviceRequest.companyCountry === null) {
        isInvalid.country = true;
      }

      if (serviceRequest.companySector === null) {
        isInvalid.sector = true;
      }

      if (serviceRequest.companyDimension === null) {
        isInvalid.dimension = true;
      }

      if (!isInvalid.name && !isInvalid.country && !isInvalid.dimension && !isInvalid.sector) {
        stepper.changeStepper('ContactStep', null, t('Contact information'));
      }

      setIsInvalid({ ...isInvalid });
    }
  };

  const handleInputChange = (event) => {
    if (event.target.name === 'companyName') {
      setAccountExists(null);
    }
    if (event.target.name === 'companyCountry' && isInvalid.country === true) {
      isInvalid.country = false;
    }
    if (event.target.name === 'companySector' && isInvalid.sector === true) {
      isInvalid.sector = false;
    }
    if (event.target.name === 'companyDimension' && isInvalid.dimension === true) {
      isInvalid.dimension = false;
    }

    changeServiceRequest({
      ...serviceRequest,
      [event.target.name]: event.target.value
    });
  };

  useEffect(() => {
    const fetchAccountList = async () => {
      const response = await fetchAccountInformation();
      setAccountInfo(response as unknown as AccountData);
    };

    fetchAccountList();
  }, []);

  useEffect(() => {
    const value = serviceRequest.companyName;
    if (value.trim() === '') {
      return;
    }

    debounce(async () => {
      const accountId = await getAccountId(value);
      if (accountId) {
        setAccountExists(true);
        changeServiceRequest({ ...serviceRequest, accountId });
      } else {
        setAccountExists(false);
        changeServiceRequest({ ...serviceRequest, accountId: null });
      }
    }, 1000);
  }, [serviceRequest.companyName]);

  useEffect(() => {
    serviceRequest.companyCountry = accountInfo?.countries.length
      ? accountInfo?.countries[0].id
      : null;
    serviceRequest.companySector = accountInfo?.sector.length
      ? accountInfo?.sector[0].id
      : null;
    serviceRequest.companyDimension = accountInfo?.dimension.length
      ? accountInfo?.dimension[0].id
      : null;
  }, [accountInfo]);

  return (
    <Row className="justify-content-center">
      <h4 className="my-4">
        {t('Information of the company')}
        <span className="required ml-1">*</span>
      </h4>
      <BootstrapForm className="w-75" noValidate onSubmit={handleSubmit}>
        <BootstrapForm.Group>
          <BootstrapForm.Label className="mb-1 mt-2">{t('Name')}</BootstrapForm.Label>
          <BootstrapForm.Control
            isInvalid={isInvalid.name}
            required
            type="text"
            onChange={(event) => {
              handleInputChange(event);
            }}
            name="companyName"
            value={serviceRequest.companyName}
            placeholder={t('Please enter the name of your company')}
          />
        </BootstrapForm.Group>
        {' '}
        {accountExists && (
          <p>
            {t('The company exists, please continue')}
          </p>
        )}
        {accountExists != null && !accountExists && (
          <BootstrapForm.Group>
            <p>
              {t('The Company does not exist')}
            </p>
            <BootstrapForm.Group>
              <BootstrapForm.Label className="mb-1 mt-2">
                {t('Country')}
                <span className="required ml-1">*</span>
              </BootstrapForm.Label>
              <BootstrapForm.Control
                isInvalid={isInvalid.country}
                as="select"
                name="companyCountry"
                onChange={handleInputChange}
                value={serviceRequest.companyCountry}
              >
                <option hidden value="">{t('Select')}</option>
                {
                  accountInfo?.countries.map((country) => (
                    <option
                      key={`${country.id}`}
                      value={country.id}
                    >
                      {country.name}
                    </option>
                  ))
                }
              </BootstrapForm.Control>
            </BootstrapForm.Group>
            <BootstrapForm.Group>
              <BootstrapForm.Label className="mb-1 mt-2">
                {t('Sector')}
                <span className="required ml-1">*</span>
              </BootstrapForm.Label>
              <BootstrapForm.Control
                isInvalid={isInvalid.sector}
                as="select"
                name="companySector"
                onChange={handleInputChange}
                value={serviceRequest.companySector}
              >
                <option hidden value="">{t('Select')}</option>
                {
                  accountInfo?.sector.map((sector) => (
                    <option
                      key={`${sector.id}`}
                      defaultValue={sector.id}
                      value={sector.id}
                    >
                      {sector.name}
                    </option>
                  ))
                }
              </BootstrapForm.Control>
            </BootstrapForm.Group>
            <BootstrapForm.Group>
              <BootstrapForm.Label className="mb-1 mt-2">
                {t('Dimension')}
                <span className="required ml-1">*</span>
              </BootstrapForm.Label>
              <BootstrapForm.Control
                isInvalid={isInvalid.dimension}
                as="select"
                name="companyDimension"
                onChange={handleInputChange}
                value={serviceRequest.companyDimension}
              >
                <option hidden value={-1} key={-1}>{t('Select')}</option>
                {
                  accountInfo?.dimension.map((dimension) => (
                    <option
                      key={`${dimension.id}`}
                      value={dimension.id}
                    >
                      {dimension.name}
                    </option>
                  ))
                }
              </BootstrapForm.Control>
            </BootstrapForm.Group>
          </BootstrapForm.Group>
        )}
        <Row className="justify-content-center mt-4">
          <Button
            onClick={() => {
              stepper.changeStepper('CompanyStep', 'CompanyTypeSubstep', t('Your expectations'));
            }}
            variant="outline-primary"
            className="w-25 mr-1"
          >
            {t('Previous')}
          </Button>
          <Button
            type="submit"
            className="btn btn-lg btn-primary w-25"
          >
            {t('Next')}
          </Button>
        </Row>
      </BootstrapForm>
    </Row>
  );
}
