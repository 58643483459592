const activeEnv
  = process.env.GATSBY_ACTIVE_ENV || process.env.NODE_ENV || 'development';
const isDevelopment = activeEnv === 'development';

let fields = {
  countryOrigen: '',
  inboudType: '',
};

let listCountryOrigin = 0;
let listInboudType = 0;

if (isDevelopment) {
  fields = {
    countryOrigen: '83',
    inboudType: '85',
  };
  listCountryOrigin = 2;
  listInboudType = 3;
}

exports.fields = fields;
exports.listCountryOrigin = listCountryOrigin;
exports.listInboudType = listInboudType;
